html{font-family:Helvetica,Tahoma,Arial,STXihei,"华文细黑","Microsoft YaHei","微软雅黑",SimSun,"宋体",Heiti,"黑体",sans-serif;-ms-text-size-adjust:100%}
body{margin:0}
footer,header,main,menu,nav{display:block}
[hidden]{display:none}
a{background-color:transparent}
a:active,a:hover{outline:0}
b,strong{font-weight:700}
h1{font-size:2em;margin:.67em 0}
sub{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sub{bottom:-.25em}
img{border:0}
@media print{
*,:after,:before{background:0 0!important;color:#000!important;box-shadow:none!important;text-shadow:none!important}
a,a:visited{text-decoration:underline}
a[href]:after{content:" ("attr(href) ")"}
a[href^="#"]:after,a[href^="javascript:"]:after{content:""}
img{page-break-inside:avoid}
img{max-width:100%!important}
h2,h3,p{orphans:3;widows:3}
h2,h3{page-break-after:avoid}
.navbar{display:none}
.label{border:1px solid #000}
}
@font-face{font-family:'Glyphicons Halflings';src:url(fonts/glyphicons/glyphicons-halflings-regular.eot);src:url(fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix) format('embedded-opentype'),url(fonts/glyphicons/glyphicons-halflings-regular.woff) format('woff'),url(fonts/glyphicons/glyphicons-halflings-regular.ttf) format('truetype'),url(fonts/glyphicons/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format('svg')}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
:after,:before{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
html{font-size:14px;-webkit-tap-highlight-color:transparent}
body{font-family:Helvetica,Tahoma,Arial,STXihei,"华文细黑","Microsoft YaHei","微软雅黑",SimSun,"宋体",Heiti,"黑体",sans-serif;line-height:1.42857143;color:#515c6b;background-color:#fff}
a{color:#515c6b;text-decoration:none}
a:focus,a:hover{color:#16191e;text-decoration:underline}
a:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px}
img{vertical-align:middle}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{font-family:inherit;font-weight:500;line-height:1.1;color:inherit}
.h1,.h2,.h3,h1,h2,h3{margin-top:18px;margin-bottom:9px}
.h4,.h5,.h6,h4,h5,h6{margin-top:9px;margin-bottom:9px}
.h1,h1{font-size:33px}
.h2,h2{font-size:27px}
.h3,h3{font-size:23px}
.h4,h4{font-size:17px}
.h5,h5{font-size:13px}
.h6,h6{font-size:12px}
p{margin:0 0 9px}
.text-left{text-align:left}
.text-right{text-align:right}
.text-primary{color:#2c2e2f}
a.text-primary:hover{color:#131415}
.text-info{color:#fff}
a.text-info:hover{color:#e6e6e6}
.page-header{padding-bottom:8px;margin:36px 0 18px;border-bottom:1px solid #eee}
ul{margin-top:0;margin-bottom:9px}
ul ul{margin-bottom:0}
.container{margin-right:auto;margin-left:auto;padding-left:15px;padding-right:15px}
@media(min-width:768px){
.container{width:750px}
}
@media(min-width:992px){
.container{width:970px}
}
@media(min-width:1200px){
.container{width:1170px}
}
.row{margin-left:-15px;margin-right:-15px}
.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9{position:relative;min-height:1px;padding-left:15px;padding-right:15px}
.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9{float:left}
.col-xs-12{width:100%}
.col-xs-11{width:91.66666667%}
.col-xs-10{width:83.33333333%}
.col-xs-9{width:75%}
.col-xs-8{width:66.66666667%}
.col-xs-7{width:58.33333333%}
.col-xs-6{width:50%}
.col-xs-5{width:41.66666667%}
.col-xs-4{width:33.33333333%}
.col-xs-3{width:25%}
.col-xs-2{width:16.66666667%}
.col-xs-1{width:8.33333333%}
.col-xs-pull-12{right:100%}
.col-xs-pull-11{right:91.66666667%}
.col-xs-pull-10{right:83.33333333%}
.col-xs-pull-9{right:75%}
.col-xs-pull-8{right:66.66666667%}
.col-xs-pull-7{right:58.33333333%}
.col-xs-pull-6{right:50%}
.col-xs-pull-5{right:41.66666667%}
.col-xs-pull-4{right:33.33333333%}
.col-xs-pull-3{right:25%}
.col-xs-pull-2{right:16.66666667%}
.col-xs-pull-1{right:8.33333333%}
.col-xs-pull-0{right:auto}
.col-xs-push-12{left:100%}
.col-xs-push-11{left:91.66666667%}
.col-xs-push-10{left:83.33333333%}
.col-xs-push-9{left:75%}
.col-xs-push-8{left:66.66666667%}
.col-xs-push-7{left:58.33333333%}
.col-xs-push-6{left:50%}
.col-xs-push-5{left:41.66666667%}
.col-xs-push-4{left:33.33333333%}
.col-xs-push-3{left:25%}
.col-xs-push-2{left:16.66666667%}
.col-xs-push-1{left:8.33333333%}
.col-xs-push-0{left:auto}
.col-xs-offset-12{margin-left:100%}
.col-xs-offset-11{margin-left:91.66666667%}
.col-xs-offset-10{margin-left:83.33333333%}
.col-xs-offset-9{margin-left:75%}
.col-xs-offset-8{margin-left:66.66666667%}
.col-xs-offset-7{margin-left:58.33333333%}
.col-xs-offset-6{margin-left:50%}
.col-xs-offset-5{margin-left:41.66666667%}
.col-xs-offset-4{margin-left:33.33333333%}
.col-xs-offset-3{margin-left:25%}
.col-xs-offset-2{margin-left:16.66666667%}
.col-xs-offset-1{margin-left:8.33333333%}
.col-xs-offset-0{margin-left:0}
@media(min-width:768px){
.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9{float:left}
.col-sm-12{width:100%}
.col-sm-11{width:91.66666667%}
.col-sm-10{width:83.33333333%}
.col-sm-9{width:75%}
.col-sm-8{width:66.66666667%}
.col-sm-7{width:58.33333333%}
.col-sm-6{width:50%}
.col-sm-5{width:41.66666667%}
.col-sm-4{width:33.33333333%}
.col-sm-3{width:25%}
.col-sm-2{width:16.66666667%}
.col-sm-1{width:8.33333333%}
.col-sm-pull-12{right:100%}
.col-sm-pull-11{right:91.66666667%}
.col-sm-pull-10{right:83.33333333%}
.col-sm-pull-9{right:75%}
.col-sm-pull-8{right:66.66666667%}
.col-sm-pull-7{right:58.33333333%}
.col-sm-pull-6{right:50%}
.col-sm-pull-5{right:41.66666667%}
.col-sm-pull-4{right:33.33333333%}
.col-sm-pull-3{right:25%}
.col-sm-pull-2{right:16.66666667%}
.col-sm-pull-1{right:8.33333333%}
.col-sm-pull-0{right:auto}
.col-sm-push-12{left:100%}
.col-sm-push-11{left:91.66666667%}
.col-sm-push-10{left:83.33333333%}
.col-sm-push-9{left:75%}
.col-sm-push-8{left:66.66666667%}
.col-sm-push-7{left:58.33333333%}
.col-sm-push-6{left:50%}
.col-sm-push-5{left:41.66666667%}
.col-sm-push-4{left:33.33333333%}
.col-sm-push-3{left:25%}
.col-sm-push-2{left:16.66666667%}
.col-sm-push-1{left:8.33333333%}
.col-sm-push-0{left:auto}
.col-sm-offset-12{margin-left:100%}
.col-sm-offset-11{margin-left:91.66666667%}
.col-sm-offset-10{margin-left:83.33333333%}
.col-sm-offset-9{margin-left:75%}
.col-sm-offset-8{margin-left:66.66666667%}
.col-sm-offset-7{margin-left:58.33333333%}
.col-sm-offset-6{margin-left:50%}
.col-sm-offset-5{margin-left:41.66666667%}
.col-sm-offset-4{margin-left:33.33333333%}
.col-sm-offset-3{margin-left:25%}
.col-sm-offset-2{margin-left:16.66666667%}
.col-sm-offset-1{margin-left:8.33333333%}
.col-sm-offset-0{margin-left:0}
}
@media(min-width:992px){
.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9{float:left}
.col-md-12{width:100%}
.col-md-11{width:91.66666667%}
.col-md-10{width:83.33333333%}
.col-md-9{width:75%}
.col-md-8{width:66.66666667%}
.col-md-7{width:58.33333333%}
.col-md-6{width:50%}
.col-md-5{width:41.66666667%}
.col-md-4{width:33.33333333%}
.col-md-3{width:25%}
.col-md-2{width:16.66666667%}
.col-md-1{width:8.33333333%}
.col-md-pull-12{right:100%}
.col-md-pull-11{right:91.66666667%}
.col-md-pull-10{right:83.33333333%}
.col-md-pull-9{right:75%}
.col-md-pull-8{right:66.66666667%}
.col-md-pull-7{right:58.33333333%}
.col-md-pull-6{right:50%}
.col-md-pull-5{right:41.66666667%}
.col-md-pull-4{right:33.33333333%}
.col-md-pull-3{right:25%}
.col-md-pull-2{right:16.66666667%}
.col-md-pull-1{right:8.33333333%}
.col-md-pull-0{right:auto}
.col-md-push-12{left:100%}
.col-md-push-11{left:91.66666667%}
.col-md-push-10{left:83.33333333%}
.col-md-push-9{left:75%}
.col-md-push-8{left:66.66666667%}
.col-md-push-7{left:58.33333333%}
.col-md-push-6{left:50%}
.col-md-push-5{left:41.66666667%}
.col-md-push-4{left:33.33333333%}
.col-md-push-3{left:25%}
.col-md-push-2{left:16.66666667%}
.col-md-push-1{left:8.33333333%}
.col-md-push-0{left:auto}
.col-md-offset-12{margin-left:100%}
.col-md-offset-11{margin-left:91.66666667%}
.col-md-offset-10{margin-left:83.33333333%}
.col-md-offset-9{margin-left:75%}
.col-md-offset-8{margin-left:66.66666667%}
.col-md-offset-7{margin-left:58.33333333%}
.col-md-offset-6{margin-left:50%}
.col-md-offset-5{margin-left:41.66666667%}
.col-md-offset-4{margin-left:33.33333333%}
.col-md-offset-3{margin-left:25%}
.col-md-offset-2{margin-left:16.66666667%}
.col-md-offset-1{margin-left:8.33333333%}
.col-md-offset-0{margin-left:0}
}
label{display:inline-block;max-width:100%;margin-bottom:5px;font-weight:700}
.open>a{outline:0}
.nav{margin-bottom:0;padding-left:0;list-style:none}
.nav>li{position:relative;display:block}
.nav>li>a{position:relative;display:block;padding:10px 15px}
.nav>li>a:focus,.nav>li>a:hover{text-decoration:none;background-color:#eee}
.nav .open>a,.nav .open>a:focus,.nav .open>a:hover{background-color:#eee;border-color:#373e4a}
.nav>li>a>img{max-width:none}
.navbar{position:relative;min-height:65px;margin-bottom:18px;border:1px solid transparent}
@media(min-width:768px){
.navbar{border-radius:0}
}
@media(min-width:768px){
.navbar-header{float:left}
}
.container>.navbar-header{margin-right:-15px;margin-left:-15px}
@media(min-width:768px){
.container>.navbar-header{margin-right:0;margin-left:0}
}
.navbar-fixed-bottom,.navbar-fixed-top{position:fixed;right:0;left:0;z-index:1030}
@media(min-width:768px){
.navbar-fixed-bottom,.navbar-fixed-top{border-radius:0}
}
.navbar-fixed-top{top:0;border-width:0 0 1px}
.navbar-fixed-bottom{bottom:0;margin-bottom:0;border-width:1px 0 0}
.navbar-toggle{position:relative;float:right;margin-right:15px;padding:9px 10px;margin-top:15.5px;margin-bottom:15.5px;background-color:transparent;background-image:none;border:1px solid transparent;border-radius:0}
.navbar-toggle:focus{outline:0}
@media(min-width:768px){
.navbar-toggle{display:none}
}
.navbar-nav{margin:11.75px -15px}
.navbar-nav>li>a{padding-top:10px;padding-bottom:10px;line-height:18px}
@media(min-width:768px){
.navbar-nav{float:left;margin:0}
.navbar-nav>li{float:left}
.navbar-nav>li>a{padding-top:23.5px;padding-bottom:23.5px}
}
.navbar-text{margin-top:23.5px;margin-bottom:23.5px}
@media(min-width:768px){
.navbar-text{float:left;margin-left:15px;margin-right:15px}
}
@media(min-width:768px){
.navbar-left{float:left!important}
.navbar-right{float:right!important;margin-right:-15px}
.navbar-right~.navbar-right{margin-right:0}
}
.label{display:inline;padding:.2em .6em .3em;font-size:75%;font-weight:700;line-height:1;color:#fff;text-align:center;white-space:nowrap;vertical-align:baseline;border-radius:.25em}
a.label:focus,a.label:hover{color:#fff;text-decoration:none;cursor:pointer}
.label:empty{display:none}
.label-primary{background-color:#2c2e2f}
.label-primary[href]:focus,.label-primary[href]:hover{background-color:#131415}
.label-info{background-color:#40bbea}
.label-info[href]:focus,.label-info[href]:hover{background-color:#18a8df}
@-webkit-keyframes progress-bar-stripes{
from{background-position:40px 0}
to{background-position:0 0}
}
@keyframes progress-bar-stripes{
from{background-position:40px 0}
to{background-position:0 0}
}
.tooltip{position:absolute;z-index:1070;display:block;visibility:visible;font-family:Arimo,"Helvetica Neue",Helvetica,Arial,sans-serif;font-size:12px;font-weight:400;line-height:1.4;opacity:0;filter:alpha(opacity=0)}
.tooltip.in{opacity:.9;filter:alpha(opacity=90)}
.tooltip.top{margin-top:-3px;padding:5px 0}
.tooltip.right{margin-left:3px;padding:0 5px}
.tooltip.bottom{margin-top:3px;padding:5px 0}
.tooltip.left{margin-left:-3px;padding:0 5px}
.tooltip-inner{max-width:200px;padding:3px 8px;color:#fff;text-align:center;text-decoration:none;background-color:#515c6b;border-radius:2px}
.popover{position:absolute;top:0;left:0;z-index:1060;display:none;max-width:276px;padding:1px;font-family:Arimo,"Helvetica Neue",Helvetica,Arial,sans-serif;font-size:13px;font-weight:400;line-height:1.42857143;text-align:left;background-color:#fff;background-clip:padding-box;border-radius:0;white-space:normal;border-radius:2px}
.popover.top{margin-top:-10px}
.popover.right{margin-left:10px}
.popover.bottom{margin-top:10px}
.popover.left{margin-left:-10px}
.popover-title{margin:0;padding:8px 14px;font-size:13px}
.popover-content{padding:9px 14px}
.container:after,.container:before,.nav:after,.nav:before,.navbar-header:after,.navbar-header:before,.navbar:after,.navbar:before,.row:after,.row:before{content:" ";display:table}
.container:after,.nav:after,.navbar-header:after,.navbar:after,.row:after{clear:both}
.pull-right{float:right!important}
.pull-left{float:left!important}
.show{display:block!important}
.hidden{display:none!important;visibility:hidden!important}
.visible-md,.visible-sm,.visible-xs{display:none!important}
@media(max-width:767px){
.visible-xs{display:block!important}
}
@media(min-width:768px) and (max-width:991px){
.visible-sm{display:block!important}
}
@media(min-width:992px) and (max-width:1199px){
.visible-md{display:block!important}
}
@media(max-width:767px){
.hidden-xs{display:none!important}
}
@media(min-width:768px) and (max-width:991px){
.hidden-sm{display:none!important}
}
@media(min-width:992px) and (max-width:1199px){
.hidden-md{display:none!important}
}
.box2{height:86px;cursor:pointer;border-radius:4px;padding:0 30px 0 30px;background-color:#fff;border-radius:4px;border:1px solid #e4ecf3;margin:20px 0 0 0;-webkit-transition:all .3s ease;-moz-transition:all .3s ease;-o-transition:all .3s ease;transition:all .3s ease}
.box2:hover{transform:translateY(-6px);-webkit-transform:translateY(-6px);-moz-transform:translateY(-6px);box-shadow:0 26px 40px -24px rgba(0,36,100,.3);-webkit-box-shadow:0 26px 40px -24px rgba(0,36,100,.3);-moz-box-shadow:0 26px 40px -24px rgba(0,36,100,.3);-webkit-transition:all .3s ease;-moz-transition:all .3s ease;-o-transition:all .3s ease;transition:all .3s ease}
.xe-comment-entry img{float:left;display:block;margin-right:10px}
.xe-comment p{margin-bottom:0}
.overflowClip_1{overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}
.overflowClip_2{overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}
.submit-tag{margin-top:50px}
.sidebar-menu .main-menu a,.user-info-navbar .user-info-menu>li>a,footer.main-footer .go-up a{-webkit-transition:all 220ms ease-in-out;-moz-transition:all 220ms ease-in-out;-o-transition:all 220ms ease-in-out;transition:all 220ms ease-in-out}
.page-container .main-content .page-title:after,.page-container .main-content .page-title:before,.sidebar-menu .logo-env:after,.sidebar-menu .logo-env:before,footer.main-footer .footer-inner:after,footer.main-footer .footer-inner:before{content:" ";display:table}
.page-container .main-content .page-title:after,.sidebar-menu .logo-env:after,footer.main-footer .footer-inner:after{clear:both}
body{background-color:#fffefa}
a{outline:0!important}
p{color:#515c6b}
body,html{height:100%;margin:0;padding:0;border:0}
.page-container{display:table;width:100%;height:100%;vertical-align:top;border-collapse:collapse;border-spacing:0;table-layout:fixed}
.page-container.container{max-width:1140px;table-layout:auto}
.page-container .main-content{display:table-cell;position:relative;z-index:1;padding:30px;padding-bottom:0;vertical-align:top;word-break:break-word;width:100%;-webkit-transition:opacity .1s ease-in-out;-moz-transition:opacity .1s ease-in-out;-o-transition:opacity .1s ease-in-out;transition:opacity .1s ease-in-out}
.page-container .main-content.is-loading{zoom:1;-webkit-opacity:.5;-moz-opacity:.5;opacity:.5;-webkit-transition:opacity .3s ease-in-out;-moz-transition:opacity .3s ease-in-out;-o-transition:opacity .3s ease-in-out;transition:opacity .3s ease-in-out}
.page-container .main-content:hover{z-index:15}
.page-container .main-content .page-title{background:#f8f8f8;margin:-30px;margin-bottom:30px;padding:20px 0;-webkit-box-shadow:0 1px 0 rgba(0,1,1,.08),inset 0 1px 0 #ededed;-moz-box-shadow:0 1px 0 rgba(0,1,1,.08),inset 0 1px 0 #ededed;box-shadow:0 1px 0 rgba(0,1,1,.08),inset 0 1px 0 #ededed}
@media screen and (max-width:768px){
.page-container .main-content .page-title{padding:15px 0}
}
.page-container .main-content .page-title .title-env{display:block}
.page-container .main-content .page-title .title-env{float:left;padding-left:30px}
.page-container .main-content .page-title .title-env .title{margin:0;font-size:27px}
@media screen and (max-width:768px){
.page-container .main-content .page-title .title-env .title{font-size:23px}
.page-container .main-content .page-title .title-env .title+p{font-size:12px}
}
@media screen and (max-width:480px){
.page-container .main-content .page-title .title-env .title{font-size:19px}
}
.page-container .main-content .page-title .title-env .title+p{margin:0;margin-top:5px;color:#515c6b}
.sidebar-menu{display:table-cell;position:relative;width:280px;background:#fffefa;z-index:1}
.sidebar-menu .logo-env{padding:19px 45px;border-bottom:1px solid #fffefa}
.sidebar-menu .logo-env .logo{float:left}
.sidebar-menu .logo-env .logo .logo-collapsed{display:none}
.sidebar-menu .main-menu{padding-left:40px;padding-right:40px;margin-top:20px;margin-bottom:20px;list-style:none}
@media screen and (max-width:768px){
.sidebar-menu .main-menu{margin-top:10px;margin-bottom:10px}
}
.sidebar-menu .main-menu a{color:#515c6b;text-decoration:none;display:block;padding:13px 5px;border-bottom:1px solid #fffefa}
.sidebar-menu .main-menu a>i{display:inline-block;margin-right:10px}
.sidebar-menu .main-menu a>i+span{padding-right:10px}
.sidebar-menu .main-menu a>span{display:inline-block}
.sidebar-menu .main-menu a>.label{margin-top:1px}
.sidebar-menu .main-menu a:hover{color:#0063b1}
.sidebar-menu .main-menu li.active>a{color:#0063b1}
.sidebar-menu .main-menu li.has-sub>a:before{position:relative;display:inline-block;font-family:"Font Awesome 5 Free";font-weight:900;content:'\f107';float:right;color:#515c6b;margin-left:10px;-webkit-transform:rotate(-90deg);-moz-transform:rotate(-90deg);-o-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg);-webkit-transition:all 150ms ease-in-out;-moz-transition:all 150ms ease-in-out;-o-transition:all 150ms ease-in-out;transition:all 150ms ease-in-out}
.sidebar-menu .main-menu li.has-sub.expanded>a:before{-webkit-transform:rotate(0);-moz-transform:rotate(0);-o-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}
.sidebar-menu .main-menu ul{display:none;margin:0;padding:0;overflow:hidden;list-style:none}
.sidebar-menu .main-menu ul li{position:relative}
@media screen and (min-width:768px){
.sidebar-menu .main-menu ul li.is-hidden{left:-20px;zoom:1;-webkit-opacity:0;-moz-opacity:0;opacity:0}
}
.sidebar-menu .main-menu ul li a{padding-left:35px}
.sidebar-menu .main-menu ul li ul li a{padding-left:60px}
.sidebar-menu .main-menu ul li ul li ul li a{padding-left:85px}
.sidebar-menu .main-menu ul li ul li ul li ul li a{padding-left:110px}
.sidebar-menu.fixed{height:100%}
.sidebar-menu.fixed .sidebar-menu-inner{position:fixed;left:0;top:0;bottom:0;width:inherit;overflow:hidden}
.page-body.right-sidebar .sidebar-menu.fixed .sidebar-menu-inner{left:auto;right:0}
@media screen and (min-width:768px){
.sidebar-menu.collapsed{width:80px;z-index:10;overflow:visible}
.sidebar-menu.collapsed .hidden-collapsed{display:none!important}
.sidebar-menu.collapsed+.main-content .user-info-navbar .user-info-menu a[data-toggle=sidebar]{color:#606161}
.sidebar-menu.collapsed+.main-content .main-footer{left:80px}
.sidebar-menu.collapsed .sidebar-menu-inner{overflow:visible;position:relative}
.sidebar-menu.collapsed .logo-env{padding:18px 0}
.sidebar-menu.collapsed .logo-env .logo-collapsed{display:block;text-align:center}
.sidebar-menu.collapsed .logo-env .logo-collapsed img{display:inline-block}
.sidebar-menu.collapsed .logo-env .logo-expanded{display:none}
.sidebar-menu.collapsed .logo-env .logo{float:none}
.sidebar-menu.collapsed .main-menu{padding-left:0;padding-right:0}
.sidebar-menu.collapsed .main-menu>li{text-align:center;position:relative}
.sidebar-menu.collapsed .main-menu>li li.active,.sidebar-menu.collapsed .main-menu>li.active{background-color:#e4ecf3}
.sidebar-menu.collapsed .main-menu>li>a>i{margin-right:0;font-size:16px}
.sidebar-menu.collapsed .main-menu>li>a>span{display:none}
.sidebar-menu.collapsed .main-menu>li>a>span.label{display:block;position:absolute;right:0;top:0}
.sidebar-menu.collapsed .main-menu>li.has-sub>a:before{display:none}
.sidebar-menu.collapsed .main-menu>li>ul{position:absolute;background:#fffefa;width:250px;top:0;left:100%;text-align:left}
.page-body.right-sidebar .sidebar-menu.collapsed .main-menu>li>ul{left:auto;right:100%}
.sidebar-menu.collapsed .main-menu>li>ul>li>a{padding-left:20px}
.sidebar-menu.collapsed .main-menu>li>ul>li>ul>li>a{padding-left:35px}
.sidebar-menu.collapsed .main-menu>li>ul>li>ul>li>ul>li>a{padding-left:50px}
.sidebar-menu.collapsed .main-menu>li>ul>li>ul>li>ul>li>ul>li>a{padding-left:65px}
.sidebar-menu.collapsed .main-menu>li>ul>li>ul>li>ul>li>ul>li>ul>li>a{padding-left:80px}
.sidebar-menu.collapsed .main-menu>li>ul li.has-sub>a:before{margin-right:10px}
.sidebar-menu.collapsed .main-menu>li:hover>ul{display:block}
}
.page-body.right-sidebar .page-container{direction:rtl}
.page-body.right-sidebar .page-container>*{direction:ltr}
footer.main-footer{padding:20px 30px;border-top:1px solid #ddd;font-size:12px;margin-left:-30px;margin-right:-30px;margin-top:10px;background-color:#fffefa}
footer.main-footer:after,footer.main-footer:before{content:" ";display:table}
footer.main-footer:after{clear:both}
footer.main-footer:after,footer.main-footer:before{content:" ";display:table}
footer.main-footer:after{clear:both}
.page-container+footer.main-footer{margin-left:0;margin-right:0}
.page-container+footer.main-footer .footer-inner{max-width:1080px;margin-left:auto;margin-right:auto}
footer.main-footer .footer-text{float:left}
footer.main-footer .go-up{float:right;margin-bottom:20px}
footer.main-footer .go-up a{display:inline-block;background-color:rgba(44,46,47,.05);padding:2px 5px}
footer.main-footer .go-up a:hover{background-color:rgba(44,46,47,.1)}
footer.main-footer.footer-type-2{background-color:#252729;border-top:0;color:#fff}
footer.main-footer.footer-type-2 a{color:#fff}
footer.main-footer.footer-type-2 .go-up a{background-color:rgba(255,255,255,.05)}
footer.main-footer.footer-type-2 .go-up a:hover{background-color:rgba(255,255,255,.1)}
footer.main-footer.fixed{position:fixed;left:0;right:0;bottom:0;margin-left:0;margin-right:0;margin-top:10px!important}
body.chat-open .user-info-navbar a[data-toggle=chat]{color:#606161}
.user-info-navbar{background-color:#fffefa;min-height:0;border:0;padding:0;margin:-30px;margin-bottom:30px}
.user-info-navbar .user-info-menu{margin:0}
.user-info-navbar .user-info-menu li{line-height:1}
.user-info-navbar .user-info-menu li a{line-height:1}
.user-info-navbar .user-info-menu li a img{line-height:1}
.user-info-navbar .user-info-menu>li{position:relative;margin:0;padding:0;border:1px solid #fff;border-top:0;border-bottom:0;display:block;float:left}
.user-info-navbar .user-info-menu>li>a{position:relative;display:block;padding:30px 20px;color:#515c6b;border-bottom:1px solid #fff;top:1px;z-index:5;text-decoration:none}
.user-info-navbar .user-info-menu>li>a i{font-size:15px;line-height:1}
.user-info-navbar .user-info-menu>li>a:hover{color:#0063b1}
.user-info-navbar .user-info-menu>li a[data-toggle=sidebar]{padding-left:25px}
.user-info-navbar .user-info-menu>li.open{border-left-color:#ddd;border-right-color:#ddd;z-index:15}
.user-info-navbar .user-info-menu>li.open>a{color:#606161}
#chat{display:table-cell;position:relative;z-index:100;width:280px;background:#fff;vertical-align:top;display:none}
body.chat-open #chat{display:table-cell}
#chat.fixed .chat-inner{position:fixed;right:0;width:inherit}
.page-body.right-sidebar #chat.fixed .chat-inner{right:auto;left:0}
#chat .chat-inner{overflow:auto;height:100%;border-left:1px solid #ddd}
.page-body.right-sidebar #chat .chat-inner{border-left:0;border-right:1px solid #ddd}
#chat .chat-inner.ps-container{overflow:hidden}
#chat .chat-header{font-size:16px;color:#515c6b;padding:29px 35px;padding-bottom:0;line-height:1;margin:0;position:relative}
@media(max-width:767px){
.chat-visible #chat,.chat-visible #chat.fixed{display:none}
.chat-visible.toggle-click #chat,.chat-visible.toggle-click #chat.fixed{display:block}
#chat,#chat.fixed{position:fixed;width:auto;top:0;left:0;right:0;bottom:0;margin:10px}
}
@media screen and (max-width:768px){
.page-container{display:block}
.page-container .main-content,.page-container .sidebar-menu{display:block;width:100%;height:auto}
.page-container .sidebar-menu{position:relative}
.page-container .sidebar-menu .sidebar-menu-inner{position:relative}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env,.page-container .sidebar-menu .sidebar-menu-inner .main-menu{padding-left:20px;padding-right:20px}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env{margin-bottom:0;padding-top:20px;padding-bottom:20px}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle{float:right}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a{position:relative;display:inline-block;color:#515c6b;line-height:1;margin-left:20px}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a i{line-height:1;font-size:18px;position:relative;top:1px}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a:active,.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a:focus,.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a:hover{color:#fff}
.page-container .sidebar-menu .sidebar-menu-inner .main-menu{display:none;margin-bottom:0}
.page-container .sidebar-menu .sidebar-menu-inner .main-menu.mobile-is-visible{display:block}
.page-container .sidebar-menu .sidebar-menu-inner .main-menu>li:last-child>a{border-bottom:0}
.page-container .main-content .navbar.user-info-navbar{display:none}
.page-container .main-content .navbar.user-info-navbar.mobile-is-visible{display:block}
.page-container .main-content .navbar.user-info-navbar .user-info-menu>li{min-height:0!important}
.page-container .main-content .navbar.user-info-navbar .user-info-menu>li>a{padding-top:20px;padding-bottom:20px}
.page-container .main-content .main-footer{position:relative;left:0;right:auto;margin-left:-30px;margin-right:-30px}
.page-container{position:relative}
.page-container #chat{left:auto;margin:0;width:340px}
.chat-open .page-container{left:-340px}
}
.label{position:relative}
.label.pull-right{margin-top:4px}
.label.pull-left{margin-top:4px;margin-right:5px}
.label.label-primary{background-color:#2c2e2f;color:#fff}
.label.label-info{background-color:#40bbea;color:#fff}
.label.label-pink{background-color:#0063b1;color:#fff;border-radius:1px}
.label.label-blue{background-color:#0e62c7;color:#fff}
.popover{padding:0;border:1px solid #ddd;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none}
.popover.popover-primary .popover-title{background-color:#2c2e2f;color:#fff;position:relative}
.popover.popover-primary.bottom .popover-title,.popover.popover-primary.top .popover-title{margin:-1px}
.popover.popover-info .popover-title{background-color:#40bbea;color:#fff;position:relative}
.popover.popover-info.bottom .popover-title,.popover.popover-info.top .popover-title{margin:-1px}
.popover.popover-blue .popover-title{background-color:#0e62c7;color:#fff;position:relative}
.popover.popover-blue.bottom .popover-title,.popover.popover-blue.top .popover-title{margin:-1px}
.tooltip .tooltip-inner{padding:10px 15px}
.tooltip.tooltip-primary .tooltip-inner{background-color:#2c2e2f}
.tooltip.tooltip-info .tooltip-inner{background-color:#40bbea}
.tooltip.tooltip-blue .tooltip-inner{background-color:#0e62c7}
label[for]{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
nav.navbar{border:0}
nav.navbar .navbar-nav li a{padding-left:20px;padding-right:20px}
@media print{
.sidebar-menu{display:none}
.main-content{padding:0!important}
.main-content .page-title,.main-content .user-info-navbar,.main-content footer.main-footer{display:none}
}
.img-container{margin-bottom:20px}
.col-margin.row>*{margin-bottom:20px}
.col-margin.row>:last-child{margin-bottom:0}
[class*=fa-]{display:inline-block;font:normal normal normal 14px/1 'Font Awesome 5 Free';font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
@-webkit-keyframes loaderAnimate{
0%{-webkit-transform:rotate(0)}
100%{-webkit-transform:rotate(220deg)}
}
@-moz-keyframes loaderAnimate{
0%{-moz-transform:rotate(0)}
100%{-moz-transform:rotate(220deg)}
}
@-o-keyframes loaderAnimate{
0%{-o-transform:rotate(0)}
100%{-o-transform:rotate(220deg)}
}
@keyframes loaderAnimate{
0%{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}
100%{-webkit-transform:rotate(220deg);-moz-transform:rotate(220deg);-ms-transform:rotate(220deg);transform:rotate(220deg)}
}
@-webkit-keyframes loaderAnimate2{
0%{box-shadow:inset #555 0 0 0 8px;-webkit-transform:rotate(-140deg)}
50%{box-shadow:inset #555 0 0 0 2px}
100%{box-shadow:inset #555 0 0 0 8px;-webkit-transform:rotate(140deg)}
}
@-moz-keyframes loaderAnimate2{
0%{box-shadow:inset #555 0 0 0 8px;-moz-transform:rotate(-140deg)}
50%{box-shadow:inset #555 0 0 0 2px}
100%{box-shadow:inset #555 0 0 0 8px;-moz-transform:rotate(140deg)}
}
@-o-keyframes loaderAnimate2{
0%{box-shadow:inset #555 0 0 0 8px;-o-transform:rotate(-140deg)}
50%{box-shadow:inset #555 0 0 0 2px}
100%{box-shadow:inset #555 0 0 0 8px;-o-transform:rotate(140deg)}
}
@keyframes loaderAnimate2{
0%{box-shadow:inset #555 0 0 0 8px;-webkit-transform:rotate(-140deg);-moz-transform:rotate(-140deg);-ms-transform:rotate(-140deg);transform:rotate(-140deg)}
50%{box-shadow:inset #555 0 0 0 2px}
100%{box-shadow:inset #555 0 0 0 8px;-webkit-transform:rotate(140deg);-moz-transform:rotate(140deg);-ms-transform:rotate(140deg);transform:rotate(140deg)}
}
@keyframes loaderAnimate2{
0%{box-shadow:inset #fff 0 0 0 17px;transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 17px;transform:rotate(140deg)}
}
@-webkit-keyframes loaderAnimate2{
0%{-webkit-transform:rotate(0)}
100%{-webkit-transform:rotate(220deg)}
}
@-moz-keyframes loaderAnimate2{
0%{-moz-transform:rotate(0)}
100%{-moz-transform:rotate(220deg)}
}
@-o-keyframes loaderAnimate2{
0%{-o-transform:rotate(0)}
100%{-o-transform:rotate(220deg)}
}
@keyframes loaderAnimate2{
0%{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}
100%{-webkit-transform:rotate(220deg);-moz-transform:rotate(220deg);-ms-transform:rotate(220deg);transform:rotate(220deg)}
}
@-webkit-keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 8px;-webkit-transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 8px;-webkit-transform:rotate(140deg)}
}
@-moz-keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 8px;-moz-transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 8px;-moz-transform:rotate(140deg)}
}
@-o-keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 8px;-o-transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 8px;-o-transform:rotate(140deg)}
}
@keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 8px;-webkit-transform:rotate(-140deg);-moz-transform:rotate(-140deg);-ms-transform:rotate(-140deg);transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 8px;-webkit-transform:rotate(140deg);-moz-transform:rotate(140deg);-ms-transform:rotate(140deg);transform:rotate(140deg)}
}
@keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 17px;transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 17px;transform:rotate(140deg)}
}
@media screen and (max-width:768px){
.text-left-sm{text-align:left}
}
@media screen and (max-width:768px){
.text-right-sm{text-align:right}
}
@media screen and (min-width:992px){
.pull-right-md{float:right}
}
@media screen and (min-width:768px){
.pull-right-sm{float:right}
}
a:hover{text-decoration:none}
label{font-weight:400}
.label{-webkit-border-radius:0;-webkit-background-clip:padding-box;-moz-border-radius:0;-moz-background-clip:padding;border-radius:0;background-clip:padding-box}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{font-weight:400}
.text-primary{color:#2c2e2f}
a.text-primary:hover{color:#131415}
.text-blue{color:#0e62c7}
a.text-blue:hover{color:#0b4b97}
.text-pink{color:#0063b1}
a.text-pink:hover{color:#ff2f32}
.text-gray{color:#515c6b}
a.text-gray:hover{color:#3b3b3b}
.text-info{color:#40bbea}
a.text-info:hover{color:#18a8df}
.calendar-env:after,.calendar-env:before{content:" ";display:table}
.calendar-env:after{clear:both}
@-webkit-keyframes glowAnimation{
0%{box-shadow:rgba(104,184,40,.2) 0 0 0 1px}
100%{box-shadow:rgba(104,184,40,.8) 0 0 3px 1px}
}
@-moz-keyframes glowAnimation{
0%{box-shadow:rgba(104,184,40,.2) 0 0 0 1px}
100%{box-shadow:rgba(104,184,40,.8) 0 0 3px 1px}
}
@-o-keyframes glowAnimation{
0%{box-shadow:rgba(104,184,40,.2) 0 0 0 1px}
100%{box-shadow:rgba(104,184,40,.8) 0 0 3px 1px}
}
@keyframes glowAnimation{
0%{box-shadow:rgba(104,184,40,.2) 0 0 0 1px}
100%{box-shadow:rgba(104,184,40,.8) 0 0 3px 1px}
}
.calendar-env{position:relative;margin-bottom:30px}
@media screen and (max-width:768px){
.calendar-env .calendar-sidebar{margin-top:30px}
}
.calendar-env .calendar-main{position:relative;background:#fff;padding:30px;z-index:5}
.calendar-env .calendar-right{float:right!important}
@media screen and (max-width:768px){
.calendar-env .calendar-right{float:none!important}
}
.calendar-env .calendar-left{float:left!important}
@media screen and (max-width:768px){
.calendar-env .calendar-left{float:none!important}
}
.xe-widget{position:relative}
.xe-widget.xe-conversations{position:relative;background:#fff;margin-bottom:0;padding:15px}
.xe-widget.xe-conversations .xe-header{display:table;width:100%;border-bottom:1px solid #eee}
.xe-widget.xe-conversations .xe-header .xe-icon,.xe-widget.xe-conversations .xe-header .xe-label{display:table-cell;vertical-align:middle;padding-bottom:20px}
.xe-widget.xe-conversations .xe-header .xe-icon{width:1%}
.xe-widget.xe-conversations .xe-header .xe-icon i{display:block;line-height:1;font-size:37px}
.xe-widget.xe-conversations .xe-header .xe-label{padding-left:10px}
.xe-widget.xe-conversations .xe-header .xe-label h3{display:block;margin:0;padding:0}
.xe-widget.xe-conversations .xe-body{border-bottom:1px solid #eee}
.xe-widget.xe-conversations .xe-body .xe-user-img{float:left;display:block;padding-right:10px}
.xe-widget.xe-conversations .xe-body .xe-comment-entry{display:table;width:100%}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment,.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-user-img{display:table-cell;vertical-align:top;padding-top:10px;padding-bottom:10px}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-user-img img{display:block}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment{width:99%;padding-left:10px}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment .xe-user-name{display:block;margin-bottom:5px}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment .xe-user-name:hover strong{color:#7d7f7f}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment p{font-size:12px}
.xe-widget.xe-conversations .xe-body ul>li{margin:10px 0;border-bottom:1px solid #eee}
.xe-widget.xe-conversations .xe-body ul>li:last-child{border-bottom:0}
.xe-widget.xe-conversations .xe-footer a{margin-top:20px;display:block;font-size:11px;color:#7d7f7f;text-align:center;text-transform:uppercase}
.xe-widget.xe-conversations .xe-footer a:hover{color:#646565}
@-webkit-keyframes weatherBgMove{
0%{left:0}
50%{left:10%}
100%{left:0}
}
@-moz-keyframes weatherBgMove{
0%{left:0}
50%{left:10%}
100%{left:0}
}
@-o-keyframes weatherBgMove{
0%{left:0}
50%{left:10%}
100%{left:0}
}
@keyframes weatherBgMove{
0%{left:0}
50%{left:10%}
100%{left:0}
}
.sidebar-user-info{background-color:#27292a;border-bottom:1px solid #323435}
@media screen and (max-width:768px){
.sidebar-user-info{display:none}
.sidebar-user-info.mobile-is-visible{display:block}
}
.sidebar-user-info+.main-menu{margin-top:10px}
@media screen and (min-width:768px){
.sidebar-menu.collapsed .sidebar-user-info+.main-menu{margin-top:0}
.sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner{position:relative}
}
.sidebar-menu .main-menu a,.user-info-navbar .user-info-menu>li>a,footer.main-footer .go-up a{-webkit-transition:all 220ms ease-in-out;-moz-transition:all 220ms ease-in-out;-o-transition:all 220ms ease-in-out;transition:all 220ms ease-in-out}
.page-container .main-content .page-title:after,.page-container .main-content .page-title:before,.sidebar-menu .logo-env:after,.sidebar-menu .logo-env:before,footer.main-footer .footer-inner:after,footer.main-footer .footer-inner:before{content:" ";display:table}
.page-container .main-content .page-title:after,.sidebar-menu .logo-env:after,footer.main-footer .footer-inner:after{clear:both}
body{background-color:#fffefa}
a{outline:0!important}
p{color:#515c6b}
body,html{height:100%;margin:0;padding:0;border:0}
.page-container{display:table;width:100%;height:100%;vertical-align:top;border-collapse:collapse;border-spacing:0;table-layout:fixed}
.page-container.container{max-width:1140px;table-layout:auto}
.page-container .main-content{display:table-cell;position:relative;z-index:1;padding:30px;padding-bottom:0;vertical-align:top;word-break:break-word;width:100%;-webkit-transition:opacity .1s ease-in-out;-moz-transition:opacity .1s ease-in-out;-o-transition:opacity .1s ease-in-out;transition:opacity .1s ease-in-out}
.page-container .main-content.is-loading{zoom:1;-webkit-opacity:.5;-moz-opacity:.5;opacity:.5;-webkit-transition:opacity .3s ease-in-out;-moz-transition:opacity .3s ease-in-out;-o-transition:opacity .3s ease-in-out;transition:opacity .3s ease-in-out}
.page-container .main-content:hover{z-index:15}
.page-container .main-content .page-title{background:#f8f8f8;margin:-30px;margin-bottom:30px;padding:20px 0;-webkit-box-shadow:0 1px 0 rgba(0,1,1,.08),inset 0 1px 0 #ededed;-moz-box-shadow:0 1px 0 rgba(0,1,1,.08),inset 0 1px 0 #ededed;box-shadow:0 1px 0 rgba(0,1,1,.08),inset 0 1px 0 #ededed}
@media screen and (max-width:768px){
.page-container .main-content .page-title{padding:15px 0}
}
.page-container .main-content .page-title .title-env{display:block}
.page-container .main-content .page-title .title-env{float:left;padding-left:30px}
.page-container .main-content .page-title .title-env .title{margin:0;font-size:27px}
@media screen and (max-width:768px){
.page-container .main-content .page-title .title-env .title{font-size:23px}
.page-container .main-content .page-title .title-env .title+p{font-size:12px}
}
@media screen and (max-width:480px){
.page-container .main-content .page-title .title-env .title{font-size:19px}
}
.page-container .main-content .page-title .title-env .title+p{margin:0;margin-top:5px;color:#515c6b}
.sidebar-menu{display:table-cell;position:relative;width:280px;background:#fffefa;z-index:1}
.sidebar-menu .logo-env{padding:19px 45px;border-bottom:1px solid #fffefa}
.sidebar-menu .logo-env .logo{float:left}
.sidebar-menu .logo-env .logo .logo-collapsed{display:none}
.sidebar-menu .main-menu{padding-left:40px;padding-right:40px;margin-top:20px;margin-bottom:20px;list-style:none}
@media screen and (max-width:768px){
.sidebar-menu .main-menu{margin-top:10px;margin-bottom:10px}
}
.sidebar-menu .main-menu a{color:#515c6b;text-decoration:none;display:block;padding:13px 5px;border-bottom:1px solid #fffefa}
.sidebar-menu .main-menu a>i{display:inline-block;margin-right:10px}
.sidebar-menu .main-menu a>i+span{padding-right:10px}
.sidebar-menu .main-menu a>span{display:inline-block}
.sidebar-menu .main-menu a>.label{margin-top:1px}
.sidebar-menu .main-menu a:hover{color:#0063b1}
.sidebar-menu .main-menu li.active>a{color:#0063b1}
.sidebar-menu .main-menu li.has-sub>a:before{position:relative;display:inline-block;font-family:"Font Awesome 5 Free";font-weight:900;content:'\f107';float:right;color:#515c6b;margin-left:10px;-webkit-transform:rotate(-90deg);-moz-transform:rotate(-90deg);-o-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg);-webkit-transition:all 150ms ease-in-out;-moz-transition:all 150ms ease-in-out;-o-transition:all 150ms ease-in-out;transition:all 150ms ease-in-out}
.sidebar-menu .main-menu li.has-sub.expanded>a:before{-webkit-transform:rotate(0);-moz-transform:rotate(0);-o-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}
.sidebar-menu .main-menu ul{display:none;margin:0;padding:0;overflow:hidden;list-style:none}
.sidebar-menu .main-menu ul li{position:relative}
@media screen and (min-width:768px){
.sidebar-menu .main-menu ul li.is-hidden{left:-20px;zoom:1;-webkit-opacity:0;-moz-opacity:0;opacity:0}
}
.sidebar-menu .main-menu ul li a{padding-left:35px}
.sidebar-menu .main-menu ul li ul li a{padding-left:60px}
.sidebar-menu .main-menu ul li ul li ul li a{padding-left:85px}
.sidebar-menu .main-menu ul li ul li ul li ul li a{padding-left:110px}
.sidebar-menu.fixed{height:100%}
.sidebar-menu.fixed .sidebar-menu-inner{position:fixed;left:0;top:0;bottom:0;width:inherit;overflow:hidden}
.page-body.right-sidebar .sidebar-menu.fixed .sidebar-menu-inner{left:auto;right:0}
@media screen and (min-width:768px){
.sidebar-menu.collapsed{width:80px;z-index:10;overflow:visible}
.sidebar-menu.collapsed .hidden-collapsed{display:none!important}
.sidebar-menu.collapsed+.main-content .user-info-navbar .user-info-menu a[data-toggle=sidebar]{color:#606161}
.sidebar-menu.collapsed+.main-content .main-footer{left:80px}
.sidebar-menu.collapsed .sidebar-menu-inner{overflow:visible;position:relative}
.sidebar-menu.collapsed .logo-env{padding:18px 0}
.sidebar-menu.collapsed .logo-env .logo-collapsed{display:block;text-align:center}
.sidebar-menu.collapsed .logo-env .logo-collapsed img{display:inline-block}
.sidebar-menu.collapsed .logo-env .logo-expanded{display:none}
.sidebar-menu.collapsed .logo-env .logo{float:none}
.sidebar-menu.collapsed .main-menu{padding-left:0;padding-right:0}
.sidebar-menu.collapsed .main-menu>li{text-align:center;position:relative}
.sidebar-menu.collapsed .main-menu>li li.active,.sidebar-menu.collapsed .main-menu>li.active{background-color:#e4ecf3}
.sidebar-menu.collapsed .main-menu>li>a>i{margin-right:0;font-size:16px}
.sidebar-menu.collapsed .main-menu>li>a>span{display:none}
.sidebar-menu.collapsed .main-menu>li>a>span.label{display:block;position:absolute;right:0;top:0}
.sidebar-menu.collapsed .main-menu>li.has-sub>a:before{display:none}
.sidebar-menu.collapsed .main-menu>li>ul{position:absolute;background:#fffefa;width:250px;top:0;left:100%;text-align:left}
.page-body.right-sidebar .sidebar-menu.collapsed .main-menu>li>ul{left:auto;right:100%}
.sidebar-menu.collapsed .main-menu>li>ul>li>a{padding-left:20px}
.sidebar-menu.collapsed .main-menu>li>ul>li>ul>li>a{padding-left:35px}
.sidebar-menu.collapsed .main-menu>li>ul>li>ul>li>ul>li>a{padding-left:50px}
.sidebar-menu.collapsed .main-menu>li>ul>li>ul>li>ul>li>ul>li>a{padding-left:65px}
.sidebar-menu.collapsed .main-menu>li>ul>li>ul>li>ul>li>ul>li>ul>li>a{padding-left:80px}
.sidebar-menu.collapsed .main-menu>li>ul li.has-sub>a:before{margin-right:10px}
.sidebar-menu.collapsed .main-menu>li:hover>ul{display:block}
}
.page-body.right-sidebar .page-container{direction:rtl}
.page-body.right-sidebar .page-container>*{direction:ltr}
footer.main-footer{padding:20px 30px;border-top:1px solid #ddd;font-size:12px;margin-left:-30px;margin-right:-30px;margin-top:10px;background-color:#fffefa}
footer.main-footer:after,footer.main-footer:before{content:" ";display:table}
footer.main-footer:after{clear:both}
footer.main-footer:after,footer.main-footer:before{content:" ";display:table}
footer.main-footer:after{clear:both}
.page-container+footer.main-footer{margin-left:0;margin-right:0}
.page-container+footer.main-footer .footer-inner{max-width:1080px;margin-left:auto;margin-right:auto}
footer.main-footer .footer-text{float:left}
footer.main-footer .go-up{float:right;margin-bottom:20px}
footer.main-footer .go-up a{display:inline-block;background-color:rgba(44,46,47,.05);padding:2px 5px}
footer.main-footer .go-up a:hover{background-color:rgba(44,46,47,.1)}
footer.main-footer.footer-type-2{background-color:#252729;border-top:0;color:#fff}
footer.main-footer.footer-type-2 a{color:#fff}
footer.main-footer.footer-type-2 .go-up a{background-color:rgba(255,255,255,.05)}
footer.main-footer.footer-type-2 .go-up a:hover{background-color:rgba(255,255,255,.1)}
footer.main-footer.fixed{position:fixed;left:0;right:0;bottom:0;margin-left:0;margin-right:0;margin-top:10px!important}
body.chat-open .user-info-navbar a[data-toggle=chat]{color:#606161}
.user-info-navbar{background-color:#fffefa;min-height:0;border:0;padding:0;margin:-30px;margin-bottom:30px}
.user-info-navbar .user-info-menu{margin:0}
.user-info-navbar .user-info-menu li{line-height:1}
.user-info-navbar .user-info-menu li a{line-height:1}
.user-info-navbar .user-info-menu li a img{line-height:1}
.user-info-navbar .user-info-menu>li{position:relative;margin:0;padding:0;border:1px solid #fff;border-top:0;border-bottom:0;display:block;float:left}
.user-info-navbar .user-info-menu>li>a{position:relative;display:block;padding:30px 20px;color:#515c6b;border-bottom:1px solid #fff;top:1px;z-index:5;text-decoration:none}
.user-info-navbar .user-info-menu>li>a i{font-size:15px;line-height:1}
.user-info-navbar .user-info-menu>li>a:hover{color:#0063b1}
.user-info-navbar .user-info-menu>li a[data-toggle=sidebar]{padding-left:25px}
.user-info-navbar .user-info-menu>li.open{border-left-color:#ddd;border-right-color:#ddd;z-index:15}
.user-info-navbar .user-info-menu>li.open>a{color:#606161}
#chat{display:table-cell;position:relative;z-index:100;width:280px;background:#fff;vertical-align:top;display:none}
body.chat-open #chat{display:table-cell}
#chat.fixed .chat-inner{position:fixed;right:0;width:inherit}
.page-body.right-sidebar #chat.fixed .chat-inner{right:auto;left:0}
#chat .chat-inner{overflow:auto;height:100%;border-left:1px solid #ddd}
.page-body.right-sidebar #chat .chat-inner{border-left:0;border-right:1px solid #ddd}
#chat .chat-inner.ps-container{overflow:hidden}
#chat .chat-header{font-size:16px;color:#515c6b;padding:29px 35px;padding-bottom:0;line-height:1;margin:0;position:relative}
@media(max-width:767px){
.chat-visible #chat,.chat-visible #chat.fixed{display:none}
.chat-visible.toggle-click #chat,.chat-visible.toggle-click #chat.fixed{display:block}
#chat,#chat.fixed{position:fixed;width:auto;top:0;left:0;right:0;bottom:0;margin:10px}
}
@media screen and (max-width:768px){
.page-container{display:block}
.page-container .main-content,.page-container .sidebar-menu{display:block;width:100%;height:auto}
.page-container .sidebar-menu{position:relative}
.page-container .sidebar-menu .sidebar-menu-inner{position:relative}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env,.page-container .sidebar-menu .sidebar-menu-inner .main-menu{padding-left:20px;padding-right:20px}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env{margin-bottom:0;padding-top:20px;padding-bottom:20px}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle{float:right}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a{position:relative;display:inline-block;color:#515c6b;line-height:1;margin-left:20px}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a i{line-height:1;font-size:18px;position:relative;top:1px}
.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a:active,.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a:focus,.page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a:hover{color:#fff}
.page-container .sidebar-menu .sidebar-menu-inner .main-menu{display:none;margin-bottom:0}
.page-container .sidebar-menu .sidebar-menu-inner .main-menu.mobile-is-visible{display:block}
.page-container .sidebar-menu .sidebar-menu-inner .main-menu>li:last-child>a{border-bottom:0}
.page-container .main-content .navbar.user-info-navbar{display:none}
.page-container .main-content .navbar.user-info-navbar.mobile-is-visible{display:block}
.page-container .main-content .navbar.user-info-navbar .user-info-menu>li{min-height:0!important}
.page-container .main-content .navbar.user-info-navbar .user-info-menu>li>a{padding-top:20px;padding-bottom:20px}
.page-container .main-content .main-footer{position:relative;left:0;right:auto;margin-left:-30px;margin-right:-30px}
.page-container{position:relative}
.page-container #chat{left:auto;margin:0;width:340px}
.chat-open .page-container{left:-340px}
}
.label{position:relative}
.label.pull-right{margin-top:4px}
.label.pull-left{margin-top:4px;margin-right:5px}
.label.label-primary{background-color:#2c2e2f;color:#fff}
.label.label-info{background-color:#40bbea;color:#fff}
.label.label-pink{background-color:#0063b1;color:#fff}
.label.label-blue{background-color:#0e62c7;color:#fff}
.popover{padding:0;border:1px solid #ddd;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none}
.popover.popover-primary .popover-title{background-color:#2c2e2f;color:#fff;position:relative}
.popover.popover-primary.bottom .popover-title,.popover.popover-primary.top .popover-title{margin:-1px}
.popover.popover-info .popover-title{background-color:#40bbea;color:#fff;position:relative}
.popover.popover-info.bottom .popover-title,.popover.popover-info.top .popover-title{margin:-1px}
.popover.popover-blue .popover-title{background-color:#0e62c7;color:#fff;position:relative}
.popover.popover-blue.bottom .popover-title,.popover.popover-blue.top .popover-title{margin:-1px}
.tooltip .tooltip-inner{padding:10px 15px}
.tooltip.tooltip-primary .tooltip-inner{background-color:#2c2e2f}
.tooltip.tooltip-info .tooltip-inner{background-color:#40bbea}
.tooltip.tooltip-blue .tooltip-inner{background-color:#0e62c7}
label[for]{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
nav.navbar{border:0}
nav.navbar .navbar-nav li a{padding-left:20px;padding-right:20px}
@media print{
.sidebar-menu{display:none}
.main-content{padding:0!important}
.main-content .page-title,.main-content .user-info-navbar,.main-content footer.main-footer{display:none}
}
.img-container{margin-bottom:20px}
.col-margin.row>*{margin-bottom:20px}
.col-margin.row>:last-child{margin-bottom:0}
[class*=fa-]{display:inline-block;font:normal normal normal 14px/1 'Font Awesome 5 Free';font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
@-webkit-keyframes loaderAnimate{
0%{-webkit-transform:rotate(0)}
100%{-webkit-transform:rotate(220deg)}
}
@-moz-keyframes loaderAnimate{
0%{-moz-transform:rotate(0)}
100%{-moz-transform:rotate(220deg)}
}
@-o-keyframes loaderAnimate{
0%{-o-transform:rotate(0)}
100%{-o-transform:rotate(220deg)}
}
@keyframes loaderAnimate{
0%{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}
100%{-webkit-transform:rotate(220deg);-moz-transform:rotate(220deg);-ms-transform:rotate(220deg);transform:rotate(220deg)}
}
@-webkit-keyframes loaderAnimate2{
0%{box-shadow:inset #555 0 0 0 8px;-webkit-transform:rotate(-140deg)}
50%{box-shadow:inset #555 0 0 0 2px}
100%{box-shadow:inset #555 0 0 0 8px;-webkit-transform:rotate(140deg)}
}
@-moz-keyframes loaderAnimate2{
0%{box-shadow:inset #555 0 0 0 8px;-moz-transform:rotate(-140deg)}
50%{box-shadow:inset #555 0 0 0 2px}
100%{box-shadow:inset #555 0 0 0 8px;-moz-transform:rotate(140deg)}
}
@-o-keyframes loaderAnimate2{
0%{box-shadow:inset #555 0 0 0 8px;-o-transform:rotate(-140deg)}
50%{box-shadow:inset #555 0 0 0 2px}
100%{box-shadow:inset #555 0 0 0 8px;-o-transform:rotate(140deg)}
}
@keyframes loaderAnimate2{
0%{box-shadow:inset #555 0 0 0 8px;-webkit-transform:rotate(-140deg);-moz-transform:rotate(-140deg);-ms-transform:rotate(-140deg);transform:rotate(-140deg)}
50%{box-shadow:inset #555 0 0 0 2px}
100%{box-shadow:inset #555 0 0 0 8px;-webkit-transform:rotate(140deg);-moz-transform:rotate(140deg);-ms-transform:rotate(140deg);transform:rotate(140deg)}
}
@keyframes loaderAnimate2{
0%{box-shadow:inset #fff 0 0 0 17px;transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 17px;transform:rotate(140deg)}
}
@-webkit-keyframes loaderAnimate2{
0%{-webkit-transform:rotate(0)}
100%{-webkit-transform:rotate(220deg)}
}
@-moz-keyframes loaderAnimate2{
0%{-moz-transform:rotate(0)}
100%{-moz-transform:rotate(220deg)}
}
@-o-keyframes loaderAnimate2{
0%{-o-transform:rotate(0)}
100%{-o-transform:rotate(220deg)}
}
@keyframes loaderAnimate2{
0%{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}
100%{-webkit-transform:rotate(220deg);-moz-transform:rotate(220deg);-ms-transform:rotate(220deg);transform:rotate(220deg)}
}
@-webkit-keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 8px;-webkit-transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 8px;-webkit-transform:rotate(140deg)}
}
@-moz-keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 8px;-moz-transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 8px;-moz-transform:rotate(140deg)}
}
@-o-keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 8px;-o-transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 8px;-o-transform:rotate(140deg)}
}
@keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 8px;-webkit-transform:rotate(-140deg);-moz-transform:rotate(-140deg);-ms-transform:rotate(-140deg);transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 8px;-webkit-transform:rotate(140deg);-moz-transform:rotate(140deg);-ms-transform:rotate(140deg);transform:rotate(140deg)}
}
@keyframes loaderAnimate22{
0%{box-shadow:inset #fff 0 0 0 17px;transform:rotate(-140deg)}
50%{box-shadow:inset #fff 0 0 0 2px}
100%{box-shadow:inset #fff 0 0 0 17px;transform:rotate(140deg)}
}
@media screen and (max-width:768px){
.text-left-sm{text-align:left}
}
@media screen and (max-width:768px){
.text-right-sm{text-align:right}
}
@media screen and (min-width:992px){
.pull-right-md{float:right}
}
@media screen and (min-width:768px){
.pull-right-sm{float:right}
}
a:hover{text-decoration:none}
label{font-weight:400}
.label{-webkit-border-radius:0;-webkit-background-clip:padding-box;-moz-border-radius:0;-moz-background-clip:padding;border-radius:0;background-clip:padding-box}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{font-weight:400}
.text-primary{color:#2c2e2f}
a.text-primary:hover{color:#131415}
.text-blue{color:#0e62c7}
a.text-blue:hover{color:#0b4b97}
.text-pink{color:#0063b1}
a.text-pink:hover{color:#ff2f32}
.text-gray{color:#515c6b}
a.text-gray:hover{color:#3b3b3b}
.text-info{color:#40bbea}
a.text-info:hover{color:#18a8df}
.sidebar-menu .main-menu ul li.is-shown{left:0;zoom:1;filter:alpha(opacity=100);-webkit-opacity:1;-moz-opacity:1;opacity:1;-webkit-transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;transition:all .2s ease-in-out}
.sidebar-menu .main-menu ul li.is-shown+.is-shown{-webkit-transition-delay:80ms;-moz-transition-delay:80ms;-o-transition-delay:80ms;transition-delay:80ms}
.sidebar-menu .main-menu ul li.is-shown+.is-shown+.is-shown{-webkit-transition-delay:120ms;-moz-transition-delay:120ms;-o-transition-delay:120ms;transition-delay:120ms}
.sidebar-menu .main-menu ul li.is-shown+.is-shown+.is-shown+.is-shown{-webkit-transition-delay:160ms;-moz-transition-delay:160ms;-o-transition-delay:160ms;transition-delay:160ms}
.sidebar-menu .main-menu ul li.hidden-item{visibility:hidden;zoom:1;filter:alpha(opacity=0);-webkit-opacity:0;-moz-opacity:0;opacity:0;-webkit-transition:all 250ms ease-in-out;-moz-transition:all 250ms ease-in-out;-o-transition:all 250ms ease-in-out;transition:all 250ms ease-in-out}
a:focus,a:hover{color:#0063b1;text-decoration:none}
p.overflowKeyword{border-radius:2px;text-align:center;background:#515c6b;color:#fff;float:right;width:32px;height:20px;margin-left:5px}
p.overflowKeyword-red{border-radius:2px;text-align:center;background:#ff4343;color:#fff;float:right;width:32px;height:20px;margin-left:5px}
p.overflowKeyword-blue{border-radius:2px;text-align:center;background:#0063b1;color:#fff;float:right;width:32px;height:20px;margin-left:5px}